<template>
  <component
    :is="tagValue"
    class="app-button-new"
    :class="componentModifiers"
    :style="widthValue"
    :href="hrefValue"
  >
    <span
      v-if="hasTitle"
      class="app-button-new__title"
    >
      <slot/>
    </span>

    <span
      v-if="hasIcon"
      class="app-button-new__icon"
    >
      <slot name="icon"/>
    </span>

    <app-loading
      v-if="isLoading"
      :inner-circle-color="innerCircleColor"
    />
  </component>
</template>

<script>
  import AppLoading from '@/components/base/app-loading'

  export default {
    name: 'app-button-new',

    components: {
      AppLoading
    },

    props: {
      /**
       * Кнопка во всю ширину контейнера?
       */
      isBlock: {
        type: Boolean,
        default: false
      },
      /**
       * Disabled?
       */
      isDisabled: {
        type: Boolean,
        default: false
      },
      /**
       * Конкретная ширина кнопки
       */
      exactWidth: {
        type: String,
        default: ''
      },
      /**
       * Цветовой стиль кнопки
       * 'green' default
       * 'white'
       * 'white-gray'
       * 'white-green'
       * 'as-link' (визуально как ссылка)
       */
      color: {
        type: String,
        default: 'green'
      },
      /**
       * Размер кнопки
       * 's'
       */
      size: {
        type: String,
        default: ''
      },
      /**
       * Ссылка
       */
      link: {
        type: String,
        default: ''
      },
      /**
       * Состояние ожидания после клика
       */
      isLoading: {
        type: Boolean,
        default: false
      },
      /**
       * Иконка идёт перед текстом?
       */
      iconFirst: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasTitle () {
        return !!this.$slots.default
      },
      hasIcon () {
        return !!this.$slots.icon
      },
      tagValue () {
        return this.link ? 'a' : 'button'
      },
      hrefValue () {
        return this.link ? this.link : null
      },
      widthValue () {
        return this.exactWidth ? `width: ${this.exactWidth}` : null
      },
      componentModifiers () {
        return {
          'app-button-new--block': this.isBlock,
          'app-button-new--disabled': this.isDisabled,
          'app-button-new--no-title': !this.hasTitle,
          'app-button-new--green': this.color === 'green',
          'app-button-new--white': this.color === 'white',
          'app-button-new--white-gray': this.color === 'white-gray',
          'app-button-new--white-green': this.color === 'white-green',
          'app-button-new--as-link': this.color === 'as-link',
          'app-button-new--size-s': this.size === 's',
          'app-button-new--icon-first': this.iconFirst,
          'app-button-new--loading': this.isLoading
        }
      },
      innerCircleColor () {
        if (this.color === 'green') {
          return '#ffffff'
        }
        return '#bad532'
      }

    }
  }
</script>

<style lang="scss">
  .app-button-new {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 48px;
    height: 48px;
    padding: 0 30px;
    line-height: 0;
    text-decoration: none;
    vertical-align: top;
    border: none;
    border-radius: 48px;
    outline: none;
    cursor: pointer;
    appearance: none;

    &--block {
      width: 100%;
    }

    &--disabled {
      opacity: .4;
      pointer-events: none;
    }

    &__title {
      font-weight: 700;
      font-size: 13px;
      font-family: DINPro, "Helvetica Neue", sans-serif;
      line-height: normal;
      white-space: nowrap;
      text-transform: uppercase;
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin: 0 0 0 15px;
    }

    &--icon-first {
      .app-button-new__title {
        order: 2;
      }
      .app-button-new__icon {
        order: 1;
        margin: 0 15px 0 0;
      }
    }

    &--no-title {
      .app-button-new__icon {
        margin: 0;
      }
    }

    &--green {
      color: #333;
      background: #bad532;

      &:hover {
        background: #d1de42;
      }

      &:active {
        background: #d1de42;
      }
    }

    &--white {
      color: #333;
      background: #fff;

      &:hover {
        background: #f9f9f9;
      }

      &:active {
        background: #f9f9f9;
      }
    }

    &--white-gray {
      color: #333;
      background: #fff;
      border: 1px #d6d6d6 solid;

      &:hover {
        border: 1px #ababab solid;
      }

      &:active {
        border: 1px #ababab solid;
      }
    }

    &--white-green {
      color: #333;
      background: #fff;
      border: 1px #bad532 solid;

      &:hover {
        border: 1px #778d1d solid;
      }

      &:active {
        border: 1px #778d1d solid;
      }
    }

    &--as-link {
      padding: 0;
      color: #bad532;
      text-decoration: underline;
      background: transparent;

      &:hover {
        text-decoration: none;
      }

      .app-button-new__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        text-transform: none;
      }
    }

    &--size-s {
      min-width: 32px;
      height: 32px;
      border-radius: 32px;

      .app-button-new__title {
        font-size: 11px;
      }

      &.app-button-new--as-link {
        .app-button-new__title {
          font-size: 14px;
        }
      }
      .app-button-new__icon {
        width: 20px;
        height: 20px;
      }
    }

    &--loading {
      cursor: default;
      pointer-events: none;

      .app-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%);
      }

      .app-button-new__title,
      .app-button-new__icon {
        opacity: 0;
      }
    }
  }
</style>


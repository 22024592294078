const russiaIcon = require('@/assets/img/ru.png')
const belarusIcon = require('@/assets/img/bl.png')
const kazakhstanIcon = require('@/assets/img/kz.png')

export const COUNTRIES = {
    ARMENIA: 'ARMENIA',
    BELARUS: 'BELARUS',
    KAZAKHSTAN: 'KAZAKHSTAN',
    KYRGYZSTAN: 'KYRGYZSTAN',
    RUSSIA: 'RUSSIA'
}

export const LANGUAGES = {
    ARMENIAN: 'ar',
    RUSSIAN: 'ru',
    BELORUSSIAN: 'bl',
    KAZAKH: 'kz',
    KYRGYZ: 'kg'
}

export const COUNTRIES_DATA = [
    {
        value: COUNTRIES.RUSSIA,
        name: 'Russia/Россия',
        languages: [LANGUAGES.RUSSIAN],
        img: russiaIcon
    },
    {
        value: COUNTRIES.BELARUS,
        name: 'Belarus/Беларусь',
        languages: [LANGUAGES.RUSSIAN],
        img: belarusIcon
    },
    {
        value: COUNTRIES.KAZAKHSTAN,
        name: 'Kazakhstan/Қазақстан',
        languages: [LANGUAGES.RUSSIAN],
        img: kazakhstanIcon
    },
]

export const SITE_LINKS = {
    [COUNTRIES.RUSSIA]: {
        [LANGUAGES.RUSSIAN]: 'https://www.demix.ru'
    },
    [COUNTRIES.ARMENIA]: {
        [LANGUAGES.ARMENIAN]: 'https://sportmaster.com/hy-am'
    },
    [COUNTRIES.BELARUS]: {
        [LANGUAGES.RUSSIAN]: 'https://www.sportmaster.by/catalog/brendy/demix'
    },
    [COUNTRIES.KAZAKHSTAN]: {
        [LANGUAGES.RUSSIAN]: 'https://sportmaster.com/ru-kz/catalog/brendy/demix'
    },
    [COUNTRIES.KYRGYZSTAN]: {
        [LANGUAGES.KYRGYZ]: 'https://sportmaster.com/ky-kg'
    }
}

export const LANGUAGES_DATA = {
    [LANGUAGES.RUSSIAN]: {
        value: LANGUAGES.RUSSIAN,
        name: 'Русский (Russian)'
    },
    [LANGUAGES.ARMENIAN]: {
        value: LANGUAGES.ARMENIAN,
        name: 'Հայերեն (Armenian)'
    },
    [LANGUAGES.KYRGYZ]: {
        value: LANGUAGES.KYRGYZ,
        name: 'Кыргызча (Kyrgyz)'
    }
}

<template>
  <div id="app">
    <redirect-form class="main-page__form"/>
  </div>
</template>

<script>
  import '@/assets/scss/app.scss'
  import RedirectForm from './components/project/redirect-form.vue'

  export default {
    name: 'App',
    components: {
      RedirectForm
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/resources";

  .app-layout {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    height: 100vh; // фикс для ie, в ie fit-content не применится
    height: fit-content; /* stylelint-disable-line declaration-block-no-duplicate-properties */
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    padding: 14px 0;

    &__logo {
      object-fit: contain;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;

    &__text {
      margin: 0;
      text-align: center;
    }
  }

  .main-page {
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      background-image: url("@/assets/img/demix-landing.png");
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__title {
      color: $white;
    }
  }
</style>

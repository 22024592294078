<template>
  <div
    v-on-clickaway="closeSelect"
    class="app-select"
    :class="componentModifiers"
  >
    <div
      class="app-select__label"
      @click.prevent="toggleSelect"
    >
      <span v-if="inputLabel" class="app-select__label-name">
        {{ inputLabel }}
      </span>

      <span class="app-select__input">

        <span class="app-select__field">
          <img
            v-if="options[model] && options[model].img"
            class="app-select__option-icon"
            :src="options[model].img"
            width="16"
            height="12"
          />

          {{ currentValue }}
        </span>

        <span class="app-select__field-box"></span>

        <span class="app-select__icon-open">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#004B75" fill-rule="evenodd" d="M5.68 7.322a.916.916 0 0 0-1.394 0 1.18 1.18 0 0 0 0 1.54l7.017 7.82a.923.923 0 0 0 1.394 0l7.017-7.82a1.18 1.18 0 0 0 0-1.54.915.915 0 0 0-1.393 0l-6.32 7.044-6.322-7.044z"/>
          </svg>
        </span>
      </span>

      <span
        v-if="isError"
        class="app-select__error-message"
      >{{ errorMessage }}</span>
    </div>

    <div
      :id="`app-select-${uid}`"
      class="app-select__drop-down shadow custom-scroll"
    >
      <div
        v-for="(option, index) in options"
        :key="index"
        class="app-select__option"
        :class="{
          'app-select__option--active': index === model
        }"
        @click.stop="changeValue(option, index)"
      >
        <img
          v-if="option.img"
          class="app-select__option-icon"
          :src="option.img"
          width="16"
          height="12"
          alt=""
        />
        <span>{{ option.name || option }}</span>
        <div
          v-if="index === model"
          class="app-select__icon-checked"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fill-rule="evenodd">
              <path fill="#778D1D" d="M12.051 3.402l1.456 1.372-5.136 5.451.019.02-.623.621-.504.536-.016-.016-.272.273L3 7.66l1.414-1.414L6.96 8.806l5.091-5.404z" transform="translate(-104 -3188) translate(100 2985) translate(0 119) translate(0 80) translate(4 4)"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { directive as onClickaway } from 'vue-clickaway'

  import scrollTo from '@/utils/scroll-to'

  export default {
    name: 'app-select',

    directives: {
      onClickaway
    },

    props: {
      /**
       * Массив опций
       * [{
       *   id: 'MALE',
       *   value: 'Мужской'
       * }]
       */
      options: {
        type: Array,
        default: () => []
      },
      /**
       * Индекс выбранной опции
       */
      activeIndex: {
        type: Number,
        default: NaN
      },
      /**
       * Текст плейсхолдера
       */
      placeholderText: {
        type: String,
        default: 'Не выбрано'
      },
      /**
       * Текст лейбла
       */
      inputLabel: {
        type: String,
        default: ''
      },
      /**
       * Компонент заблокирован
       */
      isDisabled: {
        type: Boolean,
        default: false
      },
      /**
       * Флаг ошибки
       */
      isError: {
        type: Boolean,
        default: false
      },
      /**
       * Текст сообщения об ошибке
       */
      errorMessage: {
        type: String,
        default: ''
      },
      /**
       * В строке
       */
      isInline: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isOpen: false,
        model: this.activeIndex
      }
    },

    computed: {
      componentModifiers () {
        return {
          'app-select--open': this.isOpen,
          'app-select--disabled': this.isDisabled,
          'app-select--has-error': this.isError,
          'app-select--placeholded': this.currentValue === this.placeholderText,
          'app-select--inline': this.isInline
        }
      },

      currentValue () {
        const { options, model } = this
        if (options.length && !isNaN(model)) {
          const { [model]: option } = options
          const { name } = option
          return name || option
        }
        return this.placeholderText
      },

      uid () {
        return this._uid
      }
    },

    methods: {
      async scrollToActive () {
        const selectId = `#app-select-${this.uid}`
        await this.$nextTick()
        this.scrollTo(`${selectId} .app-select__option--active`, {
          container: selectId
        })
      },

      toggleSelect () {
        if (this.isDisabled) {
          return
        }
        this.isOpen = !this.isOpen
        if (this.isOpen) { this.scrollToActive() }
      },

      changeValue (option, index) {
        /**
         * Событие установки нового значения
         * @type { string }
         * @property { string } key
         */
        this.$emit('change', {
          option,
          index
        })
        this.closeSelect()
      },

      closeSelect () {
        this.isOpen = false
      },

      scrollTo
    },

    watch: {
      activeIndex (newVal) {
        this.model = newVal
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/resources";

  .app-select {
    position: relative;
    width: 100%;
    color: $raven-gray;
    font-size: 14px;
    font-family: $main-font;
    line-height: 18px;
    text-align: start;

    &:focus-within {
      border-color: $green;
    }

    &__label {
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        .app-select__field-box {
          border-color: $granite-gray;
        }
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-local;
        content: "";
      }
    }

    &__label-name {

    }

    &__input {
      position: relative;
      display: inline-flex;
      align-items: center;
      align-self: stretch;
      box-sizing: border-box;
      height: 48px;
      margin: 6px 0 18px;
      padding: 1px 6px 1px 2px;
      background-color: $white;
      overflow: hidden;
    }

    &__field-box {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid $hex-gray;
      transition: .2s;
    }

    &__option-icon {
      object-fit: contain;
      margin-right: 10px;
    }

    &__field {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 100%;
      padding: 0 36px 0 12px;
      color: $dark-gray;
      font-weight: 400;
      font-size: 14px;
      font-family: $main-font;
      line-height: 18px;
      background-color: $white;

      &:focus,
      &:active {
        outline: none;

        & ~ .app-select__field-box {
          border-color: $green;
        }
      }

      &::placeholder {
        color: $granite-gray;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
      }
    }

    &__icon-open svg {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      z-index: $z-local;
      width: 18px;
      height: 100%;
      cursor: pointer;
      transition: .2s;

      path {
        color: $dark-gray;
      }
    }

    &__drop-down {
      position: absolute;
      top: calc(100% - 18px);
      right: -1px;
      left: -1px;
      z-index: $z-local-2;
      display: none;
      max-height: 304px;
      overflow-y: auto;
      background-color: $white;
    }

    &__option {
      position: relative;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 16px;
      color: $dark-gray;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      transition: .2s;

      &:hover {
        background-color: $lightest-gray;
      }
    }

    &__icon-checked svg {
      position: absolute;
      top: 12px;
      right: 16px;
      z-index: $z-local;
      width: 16px;
      height: 16px;

      path {
        fill: $dark-green;
      }
    }

    &--placeholded {
      .app-select__field {
        color: $granite-gray;
      }
    }

    &--disabled {
      pointer-events: none;

      .app-select__input {
        background-color: $lightest-gray;
      }

      .app-select__field {
        color: $granite-gray;
        background-color: $lightest-gray;
      }

      .app-select__icon-open path {
        fill: $granite-gray;
      }
    }

    &__error-message {
      position: relative;
      margin-top: -16px;
      color: $error;
      font-size: 12px;
      line-height: 16px;
      user-select: none;
    }

    &--has-error {
      .app-select__input {
        .app-select__field-box {
          border-color: $error;
        }
      }
    }

    &:hover {
      border-color: $raven-gray;
    }

    &--open {

      & .app-select__icon-open svg {
        transform: rotate(180deg);
      }

      & .app-select__drop-down {
        display: block;
      }
    }

    &--inline {
      .app-select__input {
        height: auto;
        margin: 0;
      }
    }
  }
</style>

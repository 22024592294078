<template>
  <div class="redirect-form">
    <app-select
      class="redirect-form__select"
      :options="countries"
      :active-index="activeCountryIndex"
      @change="setCountry"
    />
    <app-select
      v-if="languages.length"
      class="redirect-form__select"
      :options="languages"
      :is-disabled="languages.length <= 1"
      :active-index="activeLanguageIndex"
      @change="setLanguage"
    />
    <app-button
      class="redirect-form__button"
      @click.native="goToSite"
    >
      Go
    </app-button>
  </div>
</template>

<script>
  import AppButton from '@/components/base/app-button'
  import AppSelect from '@/components/base/app-select'

  import { COUNTRIES, COUNTRIES_DATA, LANGUAGES, LANGUAGES_DATA, SITE_LINKS } from '@/constants/common.js'

  export default {
    name: 'RedirectForm',

    components: {
      AppSelect,
      AppButton
    },

    data () {
      return {
        countries: COUNTRIES_DATA,
        activeCountryIndex: 0,
        languagesData: LANGUAGES_DATA,
        activeLanguageIndex: 0,
        linksData: SITE_LINKS,
        refererLink: '',
        availableRefererCountryLanguage: [{
          country: COUNTRIES.RUSSIA,
          languages: [LANGUAGES.RUSSIAN]
        }]
      }
    },

    computed: {
      languages () {
        const selectedCountryLanguages = [...this.countries[this.activeCountryIndex].languages]
        return selectedCountryLanguages.reduce((acc, item) => {
          acc.push(LANGUAGES_DATA[item])
          return acc
        }, [])
      },

      currentCountry () {
        return this.countries[this.activeCountryIndex].value
      },

      currentLanguage () {
        return this.languages[this.activeLanguageIndex].value
      },

      siteLink () {
        if (this.linksData[this.currentCountry] && this.linksData[this.currentCountry][this.currentLanguage]) {
          const domainLink = this.linksData[this.currentCountry][this.currentLanguage]
          if (this.isAvailableRefererLink) {
            return `${domainLink}${this.refererLink}`
          }
          return domainLink
        }
        return '#'
      },

      isAvailableRefererLink () {
        return this.availableRefererCountryLanguage.some(({ country, languages }) => {
          return this.currentCountry === country && languages.includes(this.currentLanguage)
        })
      }
    },

    methods: {
      setCountry ({ index }) {
        this.activeCountryIndex = index
        this.setLanguage({
          index: 0
        })
      },

      setLanguage ({ index }) {
        this.activeLanguageIndex = index
      },

      setRefererLink () {
        const pathName = window.location.pathname || ''
        const queryParams = window.location.search || ''
        this.refererLink = `${pathName}${queryParams}`
      },

      goToSite () {
        this.pushAnalyticsData()
        window.location.href = this.siteLink
      },

      pushAnalyticsData () {
        const { value: country } = this.countries[this.activeCountryIndex]
        const { value: language } = this.languages[this.activeLanguageIndex]
        window.dataLayer.push({
          event: 'dl_event',
          eventCategory: 'Goto',
          eventAction: country,
          eventLabel: language,
          eventValue: ''
        })
      }
    },

    mounted () {
      this.setRefererLink()
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/resources";

  .redirect-form {
    width: 300px;

    &__select {
      margin-bottom: 10px;
    }

    &__button {
      width: 100%;
    }
  }

  @include breakpoint('tablet') {
    .redirect-form {
      display: flex;
      align-items: center;
      width: auto;

      &__select {
        width: 242px;
        margin: 0 20px 0 0;
      }

      &__button {
        width: 200px;
        margin-bottom: 13px;
      }
    }
  }
</style>

const VueScrollTo = require('vue-scrollto')

const config = {
    duration: 0,
    easing: 'ease-in',
    offset: 0,
    force: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    cancelable: false,
    // eslint-disable-next-line id-length
    x: false,
    // eslint-disable-next-line id-length
    y: true
}

const defaultDuration = 500

function scrollTo (element, options = {}) {
    const extendedConfig = Object.assign({}, config, options)
    const duration = typeof options.duration !== 'undefined' ? options.duration : defaultDuration
    VueScrollTo.scrollTo(element, duration, extendedConfig)
}

export default scrollTo

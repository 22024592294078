<template>
  <div class="app-loading">
    <svg
      class="app-loading__icon"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="32"
        stroke-width="8"
        :stroke="outerCircleColor"
        stroke-dasharray="50.26548245743669 50.26548245743669"
        fill="none"
        stroke-linecap="round"
        transform="rotate(343.885 50.0001 50.0001)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate" dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>

      <circle
        cx="50"
        cy="50"
        r="23"
        stroke-width="8"
        :stroke="innerCircleColor"
        stroke-dasharray="36.12831551628262 36.12831551628262"
        stroke-dashoffset="36.12831551628262"
        fill="none"
        stroke-linecap="round"
        transform="rotate(-343.885 50.0001 50.0001)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 50;-360 50 50"
        ></animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'app-loading',

    props: {
      outerCircleColor: {
        type: String,
        default: '#778d1d'
      },
      innerCircleColor: {
        type: String,
        default: '#bad532'
      }
    }
  }
</script>

<style lang="scss">
  .app-loading {

    &__icon {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
</style>
